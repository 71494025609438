.h7 {
  font-size: 14px;
  margin: 5px 0;
  padding: 0;
}
.h8 {
  font-size: 12px;
  margin: 5px 0;
  padding: 0;
}
p {
  font-size: 13px !important;
}
.font-18 {
  font-size: 18px !important;
}
.font-16 {
  font-size: 16px !important;
}
// common scrolls style
.scrollbar {
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(19, 3, 71, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #57638f;
    border: 1px solid #d4d2d2;
    border-radius: 10px;
  }
}

.force-overflow {
  position: absolute;
  z-index: 1;
}
// common scrolls style

.gradient-bg {
  background-image: linear-gradient(#2c4383, rgba(96, 30, 249, 0));
  opacity: 0.5;
  display: none;
  width: 100%;
  height: 100vh;
  z-index: -1;
  position: absolute;
  filter: blur(300px);
  display: none;
}
@media (min-width: 1200px) {
  .gradient-bg {
    display: block;
  }
}

.cursor-hand {
  cursor: pointer !important;
}
.point {
  cursor: context-menu;
}

.anim-i {
  i,
  span {
    cursor: pointer;
    &:active {
      transition: 0.8s;
      transform: scale(0.8);
    }
  }
}
.pad-t {
  padding-top: 100px;
}
.pad-t1 {
  padding-top: 65px;
}
.title {
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.9px;
}

.modal-popup {
  backdrop-filter: blur(8px);
  background-color: rgba(0, 0, 0, 0.5);
}
.mms-2p {
  margin-left: -2px;
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 90%;
  }
}
.text-justify {
  text-align: justify !important;
}
.d-contents {
  display: contents !important;
}
.action-icon-t2 {
  position: absolute;
  top: 0;
  right: 6px;
}
.action-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
}
// Cleared scss files
// Profile list
.container-img {
  background: linear-gradient(
      205deg,
      rgba(189, 66, 255, 0.38) 16.1%,
      rgba(7, 31, 129, 0.38) 45.55%
    ),
    url(../../../assets/images/man-filming.jpg);
  background-size: cover;
  position: center;
  background-repeat: no-repeat;
  height: 680px;
  width: auto;
}
.title {
  padding-top: 150px;
  font-size: 80px !important;
  font-weight: 800 !important;
  line-height: 96px !important;
}
.story {
  background: linear-gradient(
    86deg,
    #bd42ff 46.03%,
    #3053e6 81.63%,
    #3c8aff 93.9%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 80px !important;
  font-weight: 800;
  line-height: normal;
}

.col-size {
  width: calc(100% / 5);
}
h4 {
  font-size: 18px;
}

@media (max-width: 575px) {
  // .container-img {
  //   height: 525px;
  // }
  .title {
    font-size: 40px !important;
    line-height: 1;
    margin: 0;
    padding-top: 25%;
  }
  .story {
    font-size: 40px !important;
    line-height: normal;
  }
  .col-size {
    width: calc(100% / 1);
  }
}
.body-bg {
  // background: #0e1429 url(../../../../assets/images/grad-bg.png) no-repeat;
  // background-size: cover;
}

@media (min-width: 576px) and (max-width: 767px) {
  .container-img {
    height: 550px;
  }
  .title {
    font-size: 40px !important;
    line-height: 1;
    margin: 0;
  }
  .story {
    font-size: 40px !important;
    line-height: normal;
  }
  .col-size {
    width: calc(100% / 2);
  }
  h4 {
    font-size: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .container-img {
    height: 420px;
  }
  .title {
    font-size: 40px !important;
    line-height: 1;
    margin: 0;
    padding-top: 20%;
  }
  .story {
    font-size: 40px !important;
    line-height: normal;
  }
  .col-size {
    width: calc(100% / 3);
  }
  h4 {
    font-size: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .container-img {
    height: 460px;
  }
  .title {
    padding-top: 80px;
    font-size: 60px !important;
    line-height: 1;
    margin: 0;
  }
  .story {
    font-size: 60px !important;
    line-height: normal;
  }
  .col-size {
    width: calc(100% / 4);
  }
}
@media (min-width: 1200px)  {
  .title {
    padding-top: 120px;
  }
}
@media (min-width: 1400px)  {
  .title {
    padding-top: 150px;
  }
}
// Profile list Ends

// Profile view component
.card {
  align-items: normal !important;
  min-height: 300px !important;
}
.text-scroll {
  overflow-y: auto;
  max-height: 200px;
}
.card-wrap {
  align-items: normal !important;
  min-height: 150px !important ;
  border-radius: 0 !important;
}
.text-height {
  max-height: 100px;
  overflow: auto;
  scrollbar-width: none;
}
// Profile view component ends

// navbar-component.scss
// h3 {
//   margin-bottom: 0 !important;
//   text-decoration: none;
//   &:hover {
//     text-decoration: underline;
//   }
// }
.nav-item.active {
  h3 {
    font-weight: 800;
  }
}
.nav-link {
  font-size: 18px !important;
}
.menu-ico {
  float: right;
  padding-top: 3px;
  display: none;
  svg {
    width: 35px;
    height: 35px;
    path {
      stroke: #fff;
    }
  }
}
.navbar-nav {
  padding-top: 11px;
}
.nav-item {
  margin: 0 1.5rem;
  h3 {
    margin: 0;
    padding: 0;
    margin-bottom: 0 !important;
  text-decoration: none;
  &:hover {
    // text-decoration: underline;
    opacity: 0.75;
  }
  }
}
.navbar-nav.show {
  display: block;
}
@media (min-width:851px) {
  .navbar-nav-inner {
    display: flex;
    justify-content: space-between !important;
  }
}
@media (max-width:850px) {
  .nav-list-ff {
    position: relative;
  }
  .menu-ico {
    display: block;
  }
  .navbar-nav {
    padding: 5px 0;
    display: none;
    position: absolute;
    top: 55px;
    background: rgba(14, 20, 41, 0.75);
    border-radius: 3px;
    right: 0;
    width: 155px;
    li {
      float: left;
      width: 100%;
      text-align: right;
    }
  }
  .navbar-nav-inner {
    .nav-item {
      margin: 5px 0;
    }
  }
}
// navbar-component.scss Ends

// Cleared scss files Ends
// Font Styling
body,
.btn,
h1,h2,h3,h4,h5,h6 {
  font-family: $font-primary !important;
  word-break: break-word;
}
h1 {
  margin-bottom: 10px !important;
}
.title1-highlighted {
  font-size: 80px !important;
  line-height: 1;
  color: #fff;
  font-weight: 800 !important;
  font-family: $font-primary !important;
  line-height: 100px !important;
}
.title1-gradient {
  background: linear-gradient(
    86deg,
    #bd42ff 46.03%,
    #3053e6 81.63%,
    #3c8aff 93.9%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 80px;
  font-weight: 800 !important;
  line-height: 80px;
  font-family: $font-primary !important;
}
h4 {
  margin-bottom: 30px !important;
}
.title2-highlighted {
  font-size: 45px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 52px !important;
  letter-spacing: 0.9px;
  font-family: $font-primary !important;
  color: #fff;
}
.title3-highlighted {
  color: #fff;
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 32px !important;
  font-family: $font-primary !important;
  letter-spacing: .0125em;
}
.title4-highlighted {
  font-size: 18px !important;
  color: #fff !important;
  line-height: 24px !important;
  letter-spacing: .03125em;
  font-weight: 500 !important;
  font-family: $font-primary !important;
}
.title4-dim {
  font-weight: 500 !important;
  font-size: 18px !important;
  color: #A1ADCF !important;
  line-height: 24px !important;
  letter-spacing: .03125em;
  font-family: $font-primary !important;
}
.title5-highlighted {
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 800 !important;
  line-height: 22px !important;
  letter-spacing: .0178571429em;
  font-family: $font-primary !important;
}
.title5-dim {
  color: #A1ADCF !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  letter-spacing: .0178571429em;
  font-family: $font-primary !important;
}
.title5-highlighted-reg {
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 22px !important;
  letter-spacing: 0.0178571429em;
  font-family: "Poppins", sans-serif !important;
}
h6 {
  margin-bottom: 5px !important;
  line-height: 14px;
}
.title6-highlighted {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  letter-spacing: .0178571429em;
  font-family: $font-primary !important;
  color: #fff;
}
.title6-dim {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  letter-spacing: .0178571429em;
  font-family: $font-primary !important;
  color: #A1ADCF !important;
}
.title-normal {
  font-size: 12px !important;
  color: #fff;
  line-height: 16px !important;
  font-family: $font-primary !important;
}
.title-normal-dim {
  font-size: 12px !important;
  color: #A1ADCF;
  line-height: 14px !important;
  font-family: $font-primary !important;
}
.hyper-link {
  color: #fff;
  text-decoration: underline;
  font-family: $font-primary !important;
}
@media (min-width:768px) {
  .container-img {
    height: 600px;
  }
}
@media (min-width:1200px) {
  .container-img {
    height: 680px;
  }
}
@media (max-width:1200px) {
  .title1-highlighted ,
  .title1-gradient {
    font-size: 60px !important;
    line-height: 90px !important;
  }
  .container-img {
    background-position: center;
  }
}
.view-toggle {
  position: absolute; 
  right: 0; 
  top: 0;
}
@media (max-width:650px) {
  .title1-highlighted ,
  .title1-gradient {
    font-size: 60px !important;
    line-height: 70px !important;
  }
}
/* file upload button */
input[type="file"].custom-upload {
  border: 1px solid #323165;
    display: block;
    width: 100%;
    border-radius: 5px;
}
input[type="file"].custom-upload::file-selector-button {
  border-radius: 4px;
  padding: 0 16px;
  height: 40px;
  cursor: pointer;
  background-color: #0c073c;
  border: 0;
  margin-right: 16px;
  transition: background-color 200ms;
  color: #fff;
}
/* file upload button hover state */
input[type="file"].custom-upload::file-selector-button:hover {
  background-color: #221e46;
}

/* file upload button active state */
input[type="file"].custom-upload::file-selector-button:active {
  background-color: #221e46;
}
nav {
  .dropdown-menu {
    left: auto !important;
    right: 0;
    background: #0a083da8;
    border: 1px solid #252525;
    li {
      width: 100%;
      a {
        color: #fff !important;
        &:hover {
          background: none !important;
          color: #b1acac !important;
        }
      }
    }
  }
}
.dropdown-toggle::after {
  display: none !important;
}
label {
  font-family: $font-primary !important;
}
.footer-fixed {
  position: fixed;
  bottom: 0;
}
.min-h-med {
  min-height: 350px !important;
}
.min-h-lg {
  min-height: 370px !important;
}
.min-h-xl {
  min-height: 396px !important;
}
// 
.jumbotron-custom {
  background: linear-gradient(205deg, #0a103414 16.1%, #0a103417 45.55%), url(../../images/man-filming-with-professional-camera.svg);
  background-size: cover; 
  padding: 100px 20px;
  text-align: center;
  min-height: 540px;
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
  .btn-lg {
    min-height: 50px;
    width: 195px;
    font-size: 16px;
  }
  .container {
    max-width: 740px;
  }
}
@media (min-width:1200px) {
  .jumbotron-custom {
    .container {
      max-width: 800px;
    }
  }
}
@media (max-width: 768px) {
  .jumbotron-custom {
    padding: 80px 15px; 
    background-position: center top; 
    min-height: 535px;
  }
}

@media (max-width: 576px) {
  .jumbotron-custom {
    padding: 60px 10px; 
  }
}
.event-custom {
  background: linear-gradient(205deg, #0a103414 16.1%, #0a103417 45.55%), url(../../images/event-bg.jpg);
  background-size: cover; 
  padding: 100px 20px;
  text-align: center;
  min-height: 540px;
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
  .btn-lg {
    min-height: 50px;
    width: 195px;
    font-size: 16px;
  }
  .container {
    max-width: 740px;
  }
}
@media (min-width:1200px) {
  .event-custom {
    .container {
      max-width: 800px;
    }
  }
}
@media (max-width: 768px) {
  .event-custom {
    padding: 80px 15px; 
    background-position: center top; 
    min-height: 535px;
  }
}

@media (max-width: 576px) {
  .event-custom {
    padding: 60px 10px; 
  }
}
.card-detail {
  position: absolute;
  bottom: 0;
  padding: 1rem 1.5rem;
  width: 100%;
}
.search-filter-wrap {
  margin: 3rem 0 0 0;
  .form-control {
    width: 300px;
    background-color: rgba(0, 0, 0, 0);
    color: #A1ADCF !important;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    border-radius: 4px;
    font-size: 14px !important;
    margin: 0 3px !important;
    &::placeholder {
      color: #A1ADCF !important;
      opacity: 1;
    }
  }
  .form-select {
    font-size: 14px !important;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0);
    color: #A1ADCF !important;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    width: 117px;
    margin: 0 3px !important;
    &:focus {
      color: #fff;
      background: rgb(11 4 70) !important;
      border-color: #4956b4;
    }
    &::placeholder {
      color: #A1ADCF !important;
      opacity: 1;
    }
  }
}
.checkbox-wrap {
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  height: 39px;
  border-radius: 4px;
  padding: 8px 8px 4px 8px;
  margin: 0 3px !important;
  label {
    color: #A1ADCF !important;
    font-size: 14px !important;
  }
  .form-check-input {
    border: 1px solid #C8C5F047 !important;
    border-radius: 0;
    background-color: transparent;
  }
}
.form-control:focus {
  color: #fff;
  background: rgb(11, 4, 70) !important;
  box-shadow: none !important;
}
.filter-item {
  // display: inline-block;
  margin: 3px;
}
.authentication-wrap {
  overflow: hidden;
  width: 100%; 
  height: 100%; 
  background: #0c101f;
  .form-control {
    font-size: 14px;
    height: 45px;
    border: 1px solid #C8C5F047;
    color: #A1ADCF !important;
    background: transparent !important;
    &::placeholder {
      color: #A1ADCF;
      opacity: 1;
    }
  }
}
.login-form {
  max-width: 385px;
}
@media (min-width:1200px) {
  .col-xl-5col {
    width: 20% !important;
  }
}
@media (max-width:767px) {
  .login-img {
    display: none;
  }
  .login-rhs {
    min-height: 100vh;
    padding-top: 3rem;
  }
}
// Authentication Slider
.overlay-text {
  position: absolute;
  bottom: 0;
  padding: 1.5rem;
}

.min-h-100px {
  min-height: 100px !important;
}
.flex-column {
  flex-direction: column !important;
}
.card-wrap.rounded-0 {
  border-radius: 0 !important;
}

.input-group-text {
  background: transparent;
  border-color: rgba(255, 255, 255, 0.1) !important;
  border-left: 0;
  color: #fff;
}
.dollar-input {
  margin-bottom: 12px;
  .form-control.input-field {
    border-right: 0 !important;
    &:focus {
      background: transparent !important;
    }
  }
}
img, video {
  max-width: 100%;
  height: auto;
}
.nofication-ff {
  float: left;
  width: 50px;
  height: 50px;
  text-align: center;
  img {
    width: 15px;
    height: 15px;
    object-fit: cover;
  }
  &.user-thumb {
    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}
.action-btn {
  float: right;
    position: absolute;
    top: 20px;
    right: 24px;
  a {
    color: #8F97AB !important;
  }
}
.min-h-auto {
  min-height: auto !important;
}
.w-50p {
  width: 60px !important;
}
img.img-project {
  display: none;
}
.project-card {
  height: 300px;
  z-index: 9;
  .box-detail-show {
    display: none;
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
  }
  img.img-project {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
  &:hover {
    .box-detail-show {
      display: block;
    }
  }
}
.view-project {
  position: absolute;
  right: 16px;
  bottom: 10px;
}
.visit-link {
  position: absolute;
  bottom: 6px;
  left: 5%;
  background: rgba(132, 136, 180, 12);
  background: #2b57eb4f;
  border-radius: 3px;
  color: #fff !important;
  padding: 5px 10px;
  img {
    width: 20px !important;
    height: 20px !important;
  }
}
.tblue-grid {
  background: rgba(108, 115, 190, 0.17);
  padding: 10px 20px;
  min-width: 75px;
  height: 75px;
  float: left;
  margin: 0 14px 10px 0;
  border-radius: 3px;
  a {
    color: #fff;
  }
}
.mat-mdc-dialog-container .mdc-dialog__surface {
  background: rgb(11, 4 ,70) !important;
}
input[type="file"].form-control {
  background: transparent !important;
  color: #A1ADCF;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-radius: 5px;
  height: 48px;
    line-height: 32px;
    font-size: 13px;
    &::file-selector-button {
    font-size: 13px;
    color: #A1ADCF;
    background: transparent !important;
    margin-right: 10px;
    border-radius: 0;
  }
}
input[type="file"].form-control::file-selector-button:hover {
  background: transparent !important;
}
.img-btns-upload {
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;
  padding: 0 5px;
  position: absolute;
  bottom: 5px;
  left: 0;
}
.profile-pic-action {
  position: absolute;
  bottom: 10px;
  right: 10px;
  .btn {
    background: #504e5c63 !important;
    min-width: auto;
    margin-left: 5px;
    i {
      color: #fff !important;
    }
  }
}
// 
.tooltip-ff {
  position: relative;
  display: inline-block;
  .tooltiptext-ff {
    visibility: hidden;
    width: 80%;
    background: #0a144d;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 5px 8px;
    font-size: 12px;
    line-height: 16px;
    position: absolute;
    left: 0;
    z-index: 110;
    text-align: left;
    border: 1px solid #5e678e;
    &.tooltip-top {
      bottom: 100% !important;
      left: 50% !important;
      margin-left: -60px !important; 
      width: 270px;
    }
  }
  
}
.tooltip-ff:hover .tooltiptext-ff {
  visibility: visible;
}
// 
// 
.custom-tooltip-ff {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.custom-tooltip-ff::before {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%; /* Position above the element */
  left: 50%;
  transform: translateX(-50%);
  background-color: #0a144d; /* Custom background color */
  color: #fff; /* Tooltip text color */
  padding: 5px 8px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
  white-space: normal; /* Allow text wrapping */
  width: 200px; /* Fixed tooltip width */
  text-align: left;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
  z-index: 10;
  pointer-events: none;
  border: 1px solid #5e678e;
}
.custom-tooltip-ff.tooltip-lg {
  &::before {
    width: 80%;
  }
}
.custom-tooltip-ff.tooltip-xl {
  &::before {
    width: 100%;
  }
}
/* Show tooltip only when there is content in data-tooltip */
.custom-tooltip-ff[data-tooltip]:hover::before {
  visibility: visible;
  opacity: 1;
}
// 
.modal-header {
  padding-bottom: 5px !important;
  border: 0 !important;
}
.sidenav-container {
  height: 100vh;
}

.nav-expansion-panel {
  box-shadow: none;
  background: transparent;
}

.content-wrapper {
  padding: 20px;
}

::ng-deep .mat-expansion-panel-body {
  padding: 0 !important;
}

.active {
  background-color: rgba(0, 0, 0, 0.1);
}

.mobile{
  display: none;
}
.lap-view{
  display: block;
}
@media (max-width:767px) {
  .mobile{
    display: block;
  }
  .lap-view{
    display: none;
  }
}

.error-snackbar {
  background-color: #f44336;
  color: white; 
}
// 2025 Jan 21
.ratio.ratio-16x9 iframe {
  max-width: 100%;
  width: 100%;
  height: 100%;
}
@media (min-width: 851px) and (max-width:1199px) {
  .nav-item {
    margin: 0 0.5rem;
  }
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
