//button 1
.brand-btn {
    transform: scale(1);
    &:hover {
        transform: scale(0.98);
        transition: 0.3s;
    }
}

//button 2
.btn-grad-ff {
    background: rgb(151,3,229);
    background: linear-gradient(33deg, rgba(151,3,229,1) 11%, rgba(0,88,221,1) 53%, rgba(22,153,234,1) 89%);
    transition: 0.3s;
    background-size: 200% auto;
    border-radius: 0.375rem;
    color: #fff !important;
    &:hover{
      background-position: right center;
      text-decoration: none;
    }
  } 
  .btn.btn-profile-view {
    min-height: 50px;
    border: 1px solid #C8C5F047 !important;
  }
  .btn-secondary-outline {
    background: transparent; 
    color: #fff; 
    border: 1px solid #C8C5F047;    
    transition: background 0.3s, color 0.3s;
  }  
  .btn-secondary-outline:hover {
    background: #6a0dad; 
    color: #fff; 
  }
  .btn {
    min-width: 100px;
  }
  .btn-sm {
    min-width: 60px;
  }
  .icon-btn {
    width: auto !important;
    display: inline-block !important;
    color: #fff !important;
    padding: 0 !important;
    margin: 0 2px !important;
  }
  // 
  .btn-grad-ff {
    i {
      margin-right: 16px;
    }
    &:disabled {
      border-color: #C8C5F047 !important;
    }
  }
  .btn.btn-w-md {
    min-width: 160px !important;
  }
  
  /* Custom File upload */
.custom-upload-ff {
  position: relative;
  width: 100%; 
  height: 100%;
  min-height: 100px;
  background: rgba(108, 115, 190, 0.17);
  border-radius: 3px; 
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    .upload-text {
      opacity: 1;
    }
  }
  .file-input {
    display: none;
  }
  .upload-label {
    text-align: center;
    color: #ffffff;
    cursor: pointer;
  }
  .upload-icon {
    margin-bottom: 10px;
  }
  .upload-text {    
    font-weight: 300;
    font-size: 12px;
    margin-top: 10px;
    display: block;
    color: #ffffff;
    opacity: 0.9;
  }
}

//youtube upload
.custom-upload {
  width: 560px; 
  height: 330px;
  min-height: 100px;
  background: rgba(108, 115, 190, 0.17);
  border-radius: 3px; 
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    .upload-text {
      opacity: 1;
    }
  }
  .file-input {
    display: none;
  }
  .upload-text {    
    font-weight: 300;
    font-size: 12px;
    margin-top: 10px;
    display: block;
    color: #ffffff;
    opacity: 0.9;
  }
}


.btn-primary-outline {
  background: rgba(108, 115, 190, 0.17);
  font-size: 16px;
  border: 0;
  border-radius: 3px;
  width: 100px;
  height: 100px;
  color: #fff;
  &:hover {
    color: #fff;
    background: rgba(108, 115, 190, 0.10);
  }
}
.btn-md {
  min-width: 75px;
  height: 35px;
  font-size: 14px;
}
.btn-outline-blue:focus,
.btn-outline-blue:hover,
.btn-outline-blue {
  background: none;
  border: 1px solid #2B57EB;
  color: #fff;
  min-height: 35px;
  margin-right: 10px;
  img {
    width: 20px;
    height: auto;
    margin-right: 10px;
  }
}
.btn.btn-t2-md {
  width: 80px;
    height: 75px;
    min-width: auto;
    font-size: 15px;
}
.btn.btn-t2-lg {
  width: 170px;
  height: 200px;
  min-width: auto;
}
.btn.btn-t2-card {
  width: 170px;
  height: 80px;
  min-width: auto;
}
.modal-header {
  .btn-close {
    background: url(../../images/close-white.png) no-repeat 0 0;
    background-size: cover;
    width: 20px;
    height: 20px;
    opacity: 1;
    padding: 0;
    right: 25px;
    position: absolute;
    top: 25px;
  }
}