.profile-pic-sm {
  img {
    width: 42px;
    height: 42px;
    border-radius: 7px;
  }
}

.profile-pic {
  margin-left: 14px !important;
  img {
    width: 246px;
    height: 324px;
    border-radius: 14px;
    object-fit: cover;
  }
}

.profile-cardPic {
  margin-bottom: 1.5rem;
  img {
    width: 100%;
    height: 225px;
    border-radius: 6px 6px 0px 0px;
    object-fit: cover;
  }
}
.profile-project {
  height: 375px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    border-radius: 6px 6px 0px 0px;
    object-fit: cover;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40%; /* Adjust for how much of the image should fade */
    background: linear-gradient(to bottom, #0806300d 0%, #050618 100%);
    pointer-events: none; /* Ensures the overlay doesn't block interactions */
  }
}
.profile-team {
  img {
    width: 93px;
    // height: 101px;
    height: 100%;
    border-radius: 6px 6px 0px 0px;
    object-fit: cover;
  }
  &.team-sm-icon {
    img {
      width: 100px !important;
      height: 100px !important;
    }
  }
}
.projects-pic {
  img {
    width: 220px;
    height: 330px;
    border-radius: 3px;
    object-fit: cover;
  }
}
.projects-pic-ff {
  img {
    width: 150px;
    height: 200px;
    object-fit: cover;
  }
}
.profile-pic {
  position: relative;
  display: inline-block;
  .spinner-border {
    position: absolute;
    top: 50%;
    left: 50%;
  }
  img {
    width: 265px;
    height: 392px;
    // height: 100%;
    border-radius: 3px;
  }
  .edit-image {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
  }
}
.gallery-pic {
  position: relative;
  padding: 0 16px 15px 0;
    float: left;
  img {
    width: 170px;
    height: 200px;
    object-fit: cover;
  }
  .gallery-del {
    position: absolute;
    top: 5px;
    right: 20px;
    background: #00000047;
    padding: 3px 6px;
    cursor: pointer;
    i {
      font-size: 12px;
    }
  }
}
.img-uploaded {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.events-pic {
  margin-left: 14px !important;
  img {
    width: 245px;
    height: 280px;
    border-radius: 14px;
    object-fit: cover;
  }
}
@media (max-width: 320px) {
  .profile-pic-sm img {
    width: 32px;
    height: 32px;
    margin-top: 4px;
  }
}