.card-wrap {
  width: 100%;
  position: relative;
  border-radius: 16px;
  min-width: 0px;
  word-wrap: break-word;
  // background: rgba(132, 136, 180, 24%);
  background: rgba(108, 115 ,190, 17%);
  border-radius: 10px !important;
  background-clip: border-box;
  margin-bottom: 20px;
  align-items: center;
  &--hover {
    cursor: pointer;
    &:hover {
      transform: translateY(-3px);
      transition: 0.4s;
      box-shadow: 0 15px 30px ease rgba(0, 0, 0, 0.3);
    }
  }
  gap: 20px;
}


