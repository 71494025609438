/* You can add global styles to this file, and also import other style files */
// @import "assets/brand-scss/style";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "ngx-toastr/toastr";
@import "../node_modules/bootstrap/scss/bootstrap";
// @import "../node_modules/font-awesome/scss/font-awesome";

// utilities
@import "assets/scss/utilities/variables";
@import "assets/scss/utilities/functions";
// base
@import "assets/scss/base/base";
@import "assets/scss/base/reset";

// layout
@import "assets/scss/layout/footer";
@import "assets/scss/layout/header";
@import "assets/scss/layout/navbar";


// module
@import "assets/scss/modules/buttons";
@import "assets/scss/modules/card";
@import "assets/scss/modules/input-fields";
@import "assets/scss/modules/table";
@import "assets/scss/modules/pictures";
@import "assets/scss/modules/pagination";
@import "assets/scss/modules/scrollbar";
