@media (max-width: 695px) {
  .mobile {
    left: 0 !important;
  }
}

.lap {
  display: block;
}
@media (max-width: 695px) {
  .lap {
    .sm {
      display: none;
    }
  }
}

.lap.sm {
  display: none;
}

.logo {
  cursor: pointer;
  img {
    height: 40px !important;
    width: auto;
  }
}
.banner {
  width: 100%;
  height: 67px;
  border-radius: 2px;
  background: rgba(32, 42, 78, 0.36);
  backdrop-filter: blur(13px);
}


@media (max-width: 991px) {
  .navbar-collapse {
    display: none !important;
  }
  .navbar-collapse.show {
    display: block !important;
    display: block !important;
    border: 1px solid #212529;
    border-radius: 5px;
    margin: 10px;
    background: #090f1e;
    padding: 1rem;
  }
}
.navbar-toggler:focus {
  box-shadow: 0px 0px 0 1px;
}
@media (max-width: 320px) {
  .logo img {
    height: 27px !important;
    width: auto;
  }
}