.logo-sm {
  cursor: pointer;
  img {
    height: auto;
    width: 100px;
  }
}

.foot {
  padding: 10px 0px;
  align-self: stretch;
  border-radius: 2px;
  border-top: 1px solid var(--border-color, rgba(200, 197, 240, 0.28));
  backdrop-filter: blur(13px);
}

.copyright-links {
  display: block;
  .sm-view {
    border-top: 1px solid #212529;
    margin-top: 5px;
    padding-top: 3px;
    display: block;
  }
}
@media (max-width: 768px) {
  .copyright-links {
    display: none;
  }
}
.copyright-links.sm-view {
  display: none;
}
@media (max-width: 768px) {
  .copyright-links.sm-view {
    justify-items: center;
    border-top: 1px solid #212529;
    margin-top: 5px;
    padding-top: 3px;
    display: block;
  }
}
ul {
  margin-bottom: 0 !important;
  padding-left: 0 !important;
}
li {
  list-style-type: none;
  display: inline-block;
  margin: 0;
  padding: 0 10px;
}
@media (max-width: 695px) {
  .copyright-links.sm-view li {
    padding: 0 3.5px;
    font-size: 7px;
  }
}
a {
  text-decoration: none;
  font-size: 14px;
}
@media (max-width: 695px) {
  a {
    font-size: 14px;
  }
}

@media (max-width: 695px) {
  p {
    font-size: 9px !important;
  }
}
.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.copyright {
  text-align: center;
  font-size: 10px;
  margin: 0;
  padding: 0 0 5px 0;
}
.social-m-icons {
  display: flex;
  a {
    margin: 0 0 0 10px;
  }
  img {
    width: 17px;
    height: 17px;
  }
}
.float-end {
  float: right;
}
@media (max-width: 768px) {
  a {
    font-size: 12px;
  }
}
footer .img-fluid {
  width: 20px;
}
footer {
  font-family: $font-primary;
  a {
    font-size: 12px;
  }
  p {
    font-size: 11px;
  }
}
@media (max-width: 768px) {
  .text-sm {
    padding: 0.5rem;
    text-align: center;
    width: 100%;
  }
}
