
.table-common {
  --bs-table-bg: ;
  border: none;
  --bs-table-color: ;
  width: 100%;
  vertical-align: top;
  position: sticky;

}
.table td{
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  border-style: none !important;
  white-space: nowrap;
  white-space: normal;
  word-wrap: break-word; 
}
.table th{
  top: 0;
  z-index: 10;
  white-space: nowrap;
}

.table-scroll{
  overflow-y: scroll;
  overflow-x: scroll;
  max-height: 80vh;
  max-width: 100%; 
  margin-right: -12px;
  padding-right: 6px;
  position: relative;
  transition: 0.5s;

  &::-webkit-scrollbar {
    width: 0;
    opacity: 0 !important;
    position: absolute;
    right: 12px;
    transition: 0.5s;
  }

  &:hover::-webkit-scrollbar {
    transition: 0.5s;
    width: 4px;
    opacity: 1 !important;
    position: absolute;
    right: 12px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    position: absolute;
    right: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #95a4db;
    position: absolute;
    right: 12px;
  }
}

.table-hig{
  height: 400px;
  max-height: 400px;
}