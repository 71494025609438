.brand-ppheader {
    inset-inline-start: 0;
    inset-inline-end: 0;
    inset-block-start: 0;
    width: 100%;
    display: flex;
    z-index: 9;
    transition: padding-inline-start 0.3s ease;
    padding-inline-start: 70px;
  
  
    &__wrapper {
      padding: 20px;
      width: 100%;
    }
  
    &__row {
      display: flex;
      align-items: center;
    }
  
    &__right-content {
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
    }
  }

  