.pagination {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .pagination-button {
    background-color: rgba(
      255,
      255,
      255,
      0
    ); /* Dark background like the one in the image */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .pagination-button:hover {
    background-color: #5a5a5a; /* Slightly lighter background on hover */
  }
  
  .pagination-button span {
    margin-left: 5px;
  }
  

  .pagination-title{
   background-color: rgba(
      255,
      255,
      255,
      0
    ); /* Dark background like the one in the image */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

  }