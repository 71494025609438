.scrollbar {
  overflow-y: scroll;
  max-height: 50vh;
  margin-right: -12px;
  padding-right: 6px;
  position: relative;
  transition: 0.5s;

  &::-webkit-scrollbar {
    width: 0;
    opacity: 0 !important;
    position: absolute;
    right: 12px;
    transition: 0.5s;
  }

  &:hover::-webkit-scrollbar {
    transition: 0.5s;
    width: 4px;
    opacity: 1 !important;
    position: absolute;
    right: 12px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    position: absolute;
    right: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #95a4db;
    position: absolute;
    right: 12px;
  }
}

.scrollbar2 {

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #5e6791;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #2a3875;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #2a3875;
  }
}
.progress {
  background: #382f73;
  height: 10px;
  .progress-bar {
    background: linear-gradient(33deg, rgb(151, 3, 229) 11%, rgb(0, 88, 221) 53%, rgb(22, 153, 234) 89%);
  }
}