// INPUT FIELD
.input-form {
  input {
    &::placeholder {
      font: 0.8rem sans-serif;
    }
  }
  textarea {
    width: 100%;
    min-width: 0px;
    outline: 2px solid transparent;
    outline-offset: 2px;
    position: relative;
    appearance: none;
    padding-top: 12px;
    padding-bottom: 8px;
    vertical-align: top;
    border-radius: 16px;
    border: 1px solid;
    font-weight: 500;
  }
}
// SEARCH FILED
.search {
  position: relative;
  i {
    top: 10px;
    left: 10px;
    position: absolute;
  }
  input {
    padding-left: 30px;
    width: 200px;
    &::placeholder {
      font-size: 15px;
    }
  }
}

.input-field {
  background-color: rgba(0, 0, 0, 0);
  // background-image: radial-gradient(
  //   circle at 0 100%,
  //   rgba(255, 255, 255, 0.08),
  //   rgba(255, 255, 255, 0.01) 90%
  // );
  color: #A1ADCF;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  min-height: 3rem;
  font-size: 13px;
  &:focus {
    color: #fff;
    background: rgb(11 4 70) !important;
    border-color: #4956b4;
    box-shadow: none;
  }
  &::placeholder {
    color: #A1ADCF;
  }
  &.search {
    font-size: 14px !important;
    min-height: 10px !important;
    width: 140px;
  }
  textarea {
    height: 136px;
  }
}
