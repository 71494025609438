@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* styles.css */

/* Default to light mode */

// $font-primary: "Roboto, " Helvetica Neue ", sans-serif";
$emoji:  "Apple Color Emoji", "Segoe UI Emoji", "Noto Color Emoji", sans-serif;
$font-primary: "Poppins", sans-serif;
$font-secondary: "Rubik", sans-serif;
$font-secondary2: "Imprima", serif;
$light-fade-bg: #363e59;


/* Default to light mode */
$global-map: (
  app-primary: rgb(11, 4, 70),
  // app-primary2: #121623,
  app-primary2: #19191c,
  app-secondary: #735dff,
  app-secondary2:rgba(164, 148, 255, 0.05),
  // font-primary:
  cfont-secondary:#8793B6,
  cfont-secondary2:#A1ADCF,
  white: #fff,
  blue: #2B57EB,
  red1: #e31a1a,
  red10: #fff5f5,
  black: #000,  
  );
  
  // font 
$global-font: (
  font-primary: $font-primary,
  font-secondary: $font-secondary,
  font-secondary2: $font-secondary2,
  
);

$dark-map: (
  app-primary: #d2d2e5,
  app-primary2: #fff,
  white: #19191c,
  black: #fff,
);
